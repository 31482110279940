




























































































































import ListCard, { ListCardType } from '@/components/ListCard.vue'
import JumpLinks from '@/components/PropertyJumpLinks.vue'
import { jumpLinksService } from '@/services'
import Vue from 'vue'

export default Vue.extend({
    name: 'TenantDetail',
    components:{JumpLinks,ListCard},
    data() {
        return {
            tenant:{} as any,
            jumpLinkData: [] as { label:string, value:string }[],
            redirectionPage: 'tenant'
        }
    },
    mounted() {
        this.getTenant()
        this.generateJumpLinkData()
    },
    methods: {
        async getTenant() {
            const tenantID = this.$route.params.tenantID

            const res = await this.axios.get(`/Tenant/GetTenant/${tenantID}`)

            this.tenant = res.data
        },
        async generateJumpLinkData(){
            
            const jumpLinks = await jumpLinksService.getJumpLinks('tenant')

            this.jumpLinkData = jumpLinks
                .filter((o)=>o.location==='tenant')
                .filter((o)=>{
                    return !o.parts
                        .filter((part: { type: string })=>part.type==='dropdown')
                        .map((part: { value: any })=>!!part.value)
                        .some(boolean=>!boolean)
                })
                .map((o)=>{
                    return {...o,parts:o.parts.map((part)=>{

                        if(part.type === 'text') return part.value
                        if(part.type === 'dropdown') return this.tenant[part.value]
                    }).join('')
                    }
                }).map((o)=>{
                    return {
                        label:o.label,
                        value:o.parts
                    }
                })
        }
    },
    computed:{
        cardLeft():ListCardType {
            return [
                {
                    label: 'Tenant Name',
                    value: this.tenant.tenantName,
                    method:'quickFilter',
                    name:'Tenant Name'
                },
                {
                    label: 'Tenant ID',
                    value: this.tenant.tenantId
                },
                {
                    label: 'Parent Name',
                    value: this.tenant.parentName
                },
                {
                    label: 'Parent ID',
                    value: this.tenant.parentId
                },
                {
                    label: 'Billing Name',
                    value: this.tenant.contactBillingName
                },
                {
                    label: 'Billing Address',
                    value: this.tenant.contactBillingAddress
                },
                {
                    label: 'Billing City',
                    value: this.tenant.contactBillingCity
                },
                {
                    label: 'Billing Postal Code',
                    value: this.tenant.contactBillingPostalCode
                },
                {
                    label: 'Billing Province',
                    value: this.tenant.contactBillingProvince
                },
                {
                    label: 'Email',
                    value: this.tenant.contactEmail
                },
            ]
        },
        cardCenter(): ListCardType {
            return [
                {
                    label: 'Business Unit',
                    value: this.tenant.property,
                },
                {
                    label: 'Property Name',
                    value: this.tenant.propertyName,
                },
                {
                    label: 'Address',
                    value: this.tenant.address,
                },
                {
                    label: 'City',
                    value: this.tenant.city,
                },
                {
                    label: 'Postal Code',
                    value: this.tenant.postalCode,
                },
                {
                    label: 'Province',
                    value: this.tenant.province,
                },
                {
                    label: 'Product',
                    value: this.tenant.product,
                },
                {
                    label: 'Region',
                    value: this.tenant.region,
                },
                {
                    label: 'Property Manager',
                    value: this.tenant.propertyManager,
                },
                {
                    label: 'Leasing Manager',
                    value: this.tenant.leasingManager,
                },
            ]
        },
        cardRight(): ListCardType  {
            return [
                {
                    label: 'Lease Term (in Years)',
                    value: this.tenant.termInYears,
                },
                {
                    label: 'Annual Rent Amount',
                    value: this.tenant.annualBaseRentFromLeaseContract,
                    type:'number'
                },
                {
                    label: 'AR30',
                    value: this.tenant.ar30,
                    type:'number'
                },
                {
                    label: 'AR60',
                    value: this.tenant.ar60,
                    type:'number'
                },
                {
                    label: 'AR90',
                    value: this.tenant.ar90,
                    type:'number'
                },
                {
                    label: 'AR120',
                    value: this.tenant.ar120,
                    type:'number'
                },
                {
                    label: 'AR Total',
                    value: this.tenant.arTotal,
                    type:'number'
                },
            ]
        }
    }
})
