var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{staticClass:"primary white--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('v-card-text',[_c('v-list',_vm._l((_vm.detail),function(ref,index){
var label = ref.label;
var value = ref.value;
var type = ref.type; if ( type === void 0 ) type = null;
var dialog = ref.dialog; if ( dialog === void 0 ) dialog = [];
var method = ref.method;
var name = ref.name;
return _c('v-row',{key:index,staticClass:"my-0"},[_c('v-col',{staticClass:"font-weight-bold text-right p-small",attrs:{"cols":"5"}},[_vm._v(" "+_vm._s(label)+" ")]),_c('v-col',{staticClass:"p-small",attrs:{"cols":"7"}},[(type === 'number')?_c('div',{staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(_vm._f("formatNumber")(value))+" ")]):(type === 'percentage')?_c('div',{staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(value)+"% ")]):(method=='quickFilter')?_c('div',{staticStyle:{"display":"inline"}},[_c('button',{staticClass:"quicklink",on:{"click":function($event){return _vm.addFilter(name,value)}}},[_vm._v(" "+_vm._s(value)+" ")])]):(type === 'currency')?_c('div',{staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(_vm._f("formatCurrencyNoCents")(value))+" ")]):_c('div',{staticStyle:{"display":"inline"}},[_vm._v(" "+_vm._s(value)+" ")]),(_vm.isNotEmpty(dialog))?_c('dialog-box',{attrs:{"title":_vm.createTitle(label, value)}},_vm._l((dialog),function(item,index){return _c('v-row',{key:index,staticClass:"mt-2"},[_c('v-col',{staticClass:"font-weight-bold text-right",staticStyle:{"color":"rgba(0, 0, 0, 1)","padding":"0.375rem"},attrs:{"cols":"4"}},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('v-col',{staticStyle:{"padding":"0.375rem"},attrs:{"cols":"8"}},[_vm._v(" "+_vm._s(item.value)+" ")])],1)}),1):_vm._e()],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }