



















import Vue from 'vue'

export default Vue.extend({
    name: 'JumpLinks',
    props: {
        links: Array,
    },
    methods:{
        back(){
            this.$router.go(-1)
        }
    },
    computed: {
        filtered():any {
            return this.links.filter((obj:any)=>{
                return Boolean(obj.value)
            })
        }
    }
})
