
























































































import Vue from 'vue'
import DialogBox from '@/components/DialogBox.vue'

type Dialog = { label: string; value: string }
export type ListCardType = {
  label: string;
  value: string;
  type?: 'number'|'percentage'|'currency';
  dialog?: Dialog[];
  method?: string;
  name?:string;
}[];


export default Vue.extend({
    components: { DialogBox },
    name: 'PropertyDetailList',
    props: {
        title: String,
        detail: Array as any,
        redirectionPage: {
            type: String,
            default: 'property'
        }
    },
    methods: {
        isNotEmpty:(arr: Dialog[])=> arr.some((v) => Boolean(v.value)),
        createTitle:(label: string, value: string)=> `${label}: ${value}`,
        addFilter(field:string,value:string, type?: string | null){
            const typeDict: Record<string,string> = {
                'number': 'number',
                'currency': 'number',
                'percentage': 'number',
            }
            const derivedType = (type && typeDict[type]) || type || 'string'

            const searchField = {
                field,
                operator: derivedType == 'string' ? 'exact match' : 'greater than',
                value,
                logic: 'and',
            }
            
            this.$router.push(`/${this.redirectionPage}?q=`+JSON.stringify({fields:[searchField]}))
        },
    },
})
